import { useState, useEffect } from 'react'

import { urlFor, client } from '../client'

import { Container, Row, Col } from 'react-bootstrap'

const About = () => {
  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    const query = '*[_type == "abouts"]'

    client.fetch(query).then((data) => {
      setAbouts(data)
    })
  }, [])

  return (
    <>
      <Container fluid>
        {abouts.slice(0, 1).map((about, index) => (
          <Row xs={1} lg={2} key={about.background + index} className='mb-5'>
            <Col className='align-self-center'>
              <img
                src={urlFor(about.imgUrl).url()}
                className='sanity-img'
                alt={about.education}
              />
            </Col>
            <Col className='about-col align-self-center mt-3'>
              <h1 className='mb-5 about-title'>{about.title}</h1>
              <h4 className='my-3'>{about.background}</h4>
              <h4 className='mt-5'>{about.experience}</h4>
            </Col>
          </Row>
        ))}
      </Container>
    </>
  )
}

export default About
