import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import { Header, Footer } from './components'
import { Home, Photos, Videos, Contact } from './pages'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className='app'>
      <Router>
        <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/photos' element={<Photos />} />
            <Route path='/videos' element={<Videos />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
