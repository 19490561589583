import AnimatedPage from './AnimatedPage'
import { Video } from '../components'
import { CaretDown } from 'react-bootstrap-icons'
import { Container, Row, Col } from 'react-bootstrap'
import { motion } from 'framer-motion'
// import LiteYouTubeEmbed from 'react-lite-youtube-embed'
// import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const Videos = () => {
  return (
    <AnimatedPage>
      <div className='gallery justify-content-center mt-5 py-3'>
        <h1 className='photos-title'>Welcome to the Studio.</h1>
      </div>
      <motion.a
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.4 },
        }}
        whileTap={{ scale: 0.9 }}
        href='#videos-location'
        className='arrow-down justify-content-center'
      >
        <CaretDown size={50} />
      </motion.a>
      <Container fluid className='mt-5'>
        <div className='arrow-down mb-2'>
          <h1>WEDDINGS</h1>
        </div>
        <Row xs={1} md={2} lg={3} className='justify-content-center mb-5'>
          <Video embed='beOTBwOgWjk' title='Meagan and Matthew Wedding' />
          <Video embed='xvXgoq0-a9Q' title='The Couples' />

          <Video embed='EOZOQaD7nv8' title='Wedding Demo Reel' />
        </Row>

        <div className='arrow-down mb-2'>
          <h1>MORE PROJECTS</h1>
        </div>
        <Row
          xs={1}
          md={2}
          lg={3}
          className='justify-content-center pb-5'
          id='videos-location'
        >
          <Video embed='ft7W5zTUbPs' title='Looking After You' />
          <Video embed='oZdJLClqfOQ' title='RedRoom' />
          <Video embed='bQH3n8VJDVM' title='Feeding Positivity' />
          <Video embed='Wr0St_pFykU' title='Writers Block' />
          <Video embed='VvMTSEpIBuo' title='First Impressions' />
          <Video embed='vkPcGbeGAl8' title='Why I Dont Drink Anymore' />
          <Video embed='cDgn8wmLdG0' title='Getting Help' />
          <Video
            embed='JARfZUc_iQ0'
            title='Lost in my own thoughts - Sydney Cope'
          />
        </Row>
      </Container>
    </AnimatedPage>
  )
}

export default Videos
