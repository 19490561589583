import { Col } from 'react-bootstrap'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const Video = ({ embed, title }) => {
  return (
    <Col className='mb-3'>
      <LiteYouTubeEmbed id={embed} title={title} />
    </Col>
  )
}

export default Video
