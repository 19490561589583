import { Container, Navbar, Nav } from 'react-bootstrap'
import { House, Camera, CameraReels, Envelope } from 'react-bootstrap-icons'
import Logo from '../assets/logo.png'

function Header() {
  return (
    <>
      <Navbar
        collapseOnSelect
        className='navbar'
        fixed='top'
        expand='lg'
        variant='dark'
      >
        <Container fluid>
          <Navbar.Brand href='/'>
            <img
              src={Logo}
              className='d-inline-block align-top nav-logo ml-4'
              alt='Ferranchizze Films logo'
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            className='mr-4'
          />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ml-auto mr-3'>
              <Nav.Link className='nav-link align-middle' href='/'>
                <h5 className='nav-item'>
                  <House className='mb-1' />
                  <span> </span>
                  Home
                </h5>
              </Nav.Link>
              <Nav.Link className='nav-link' href='/photos'>
                <h5 className='nav-item'>
                  <Camera className='mb-1' />
                  <span> </span>
                  Gallery
                </h5>
              </Nav.Link>
              <Nav.Link className='nav-link' href='/videos'>
                <h5 className='nav-item'>
                  <CameraReels className='mb-1' />
                  <span> </span>
                  Videos
                </h5>
              </Nav.Link>
              <Nav.Link className='nav-link' href='/contact'>
                <h5 className='nav-item'>
                  <Envelope className='mb-1' />
                  <span> </span>Contact
                </h5>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
