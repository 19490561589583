import React from 'react'
import AnimatedPage from './AnimatedPage'
import { Container, Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { urlFor, client } from '../client'
import { CaretDown } from 'react-bootstrap-icons'
import { motion } from 'framer-motion'

const Photos = () => {
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    const query = '*[_type == "photos"]'

    client.fetch(query).then((data) => {
      setPhotos(data)
    })
  }, [])

  return (
    <AnimatedPage>
      <div className='gallery justify-content-center mt-5 py-3'>
        <h1 className='photos-title'>Welcome to the Gallery.</h1>
      </div>
      <motion.a
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.4 },
        }}
        whileTap={{ scale: 0.9 }}
        href='#images-location'
        className='arrow-down justify-content-center'
      >
        <CaretDown size={50} />
      </motion.a>
      <Container fluid className='my-5'>
        <Row xs={1} md={2} lg={3} className='justify-content-center'>
          {photos.slice(0, 49).map((photo, index) => (
            <Col key={photo.description + index} id='images-location'>
              <img
                src={urlFor(photo.imgUrl).url()}
                className='sanity-img pt-3'
                alt={photo.description}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </AnimatedPage>
  )
}

export default Photos
