import { Container, Col, Row } from 'react-bootstrap'
import About from '../components/About'
import AnimatedPage from './AnimatedPage'
import { Parallax } from 'react-parallax'
import Andrew from '../assets/Andrew.jpeg'
import videoBg from '../assets/bgVideo.mp4'
import home1 from '../assets/home-1.jpeg'
import home2 from '../assets/home-2.jpeg'
import home3 from '../assets/home-3.jpeg'

const Home = () => {
  return (
    // <AnimatedPage>
    //   <Parallax strength={-100} bgImage={Andrew}>
    //     <div className='landing'>
    //       <div className='title'>FERRANCHIZZE FILMS</div>
    //     </div>
    //   </Parallax>

    // </AnimatedPage>
    <>
      <div className='landing'>
        <div className='overlay'></div>
        <video src={videoBg} autoPlay loop muted playsInline controls={false} />
        <div className='title'>FERRANCHIZZE FILMS</div>
      </div>

      <About />
      <Container fluid className='my-5'>
        <Row xs={1} md={2} lg={3} className='justify-content-center'>
          <Col>
            <img src={home1} className='sanity-img pt-3' />
          </Col>
          <Col>
            <img src={home2} className='sanity-img pt-3' />
          </Col>
          <Col>
            <img src={home3} className='sanity-img pt-3' />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home
