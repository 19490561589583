import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedPage from './AnimatedPage'
import { ToastContainer, toast } from 'react-toastify'
import { CaretDown } from 'react-bootstrap-icons'
import { Container, Row, Col } from 'react-bootstrap'
import { motion } from 'framer-motion'

const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text)

          toast.success(
            'Success!! A representative from Ferranchizze Films will be in touch soon.',
            {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            }
          )
        },
        (error) => {
          console.log(error.text)

          toast.error(
            'There seems to have been an error submitting the form. Please try again.',
            {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
              className: 'toast-notification',
            }
          )
        }
      )

    e.target.reset()
  }

  return (
    <AnimatedPage>
      <div className='gallery justify-content-center mt-5 py-3'>
        <h1 className='photos-title'>Contact Us.</h1>
      </div>
      <motion.a
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.4 },
        }}
        whileTap={{ scale: 0.9 }}
        href='#contact-location'
        className='arrow-down justify-content-center'
      >
        <CaretDown size={50} />
      </motion.a>

      <Container fluid className='pt-5'>
        <form
          ref={form}
          onSubmit={sendEmail}
          className='contact-form'
          id='contact-location'
        >
          <Row xs={1} className='justify-content-center'>
            <Col>
              <h3 className='my-4 contact-info'>
                We look forward to working with you!
              </h3>
            </Col>
            <Col>
              <h3 className='my-4 contact-info'>
                Email: ferranchizzefilms@gmail.com
              </h3>
            </Col>
            <Col>
              <h3 className='mb-5 contact-info'>Number: 772-408-3076</h3>
            </Col>
          </Row>
          <Row xs={1} md={2} className='justify-content-center'>
            <Col>
              <label>NAME</label>
              <input type='text' name='user_name' required />
            </Col>
            <Col>
              <label>SUBJECT</label>
              <input type='subject' name='user_subject' required />
            </Col>
            <Col>
              <label>EMAIL</label>
              <input type='email' name='user_email' required />
            </Col>
            <Col>
              <label>PHONE</label>
              <input
                type='tel'
                name='user_number'
                pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                required
              />
            </Col>
          </Row>
          <Row xs={1} className='justify-content-center'>
            <Col>
              <label>MESSAGE</label>
              <textarea name='message' />
            </Col>
          </Row>
          <Row xs={1} className='justify-content-center'>
            <Col>
              <input
                type='submit'
                value='Send'
                id='submit-btn'
                className='btn'
              />
            </Col>
          </Row>
        </form>
      </Container>

      <ToastContainer />
    </AnimatedPage>
  )
}

export default Contact
